import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export class AuthInput {
  email: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Case {
  bgIcon?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Scalars['String']>;
  bgRGBA?: Maybe<Scalars['String']>;
  caseUser: Array<UserAnswer>;
  client?: Maybe<Scalars['String']>;
  conclusion?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  gallery?: Maybe<Array<CaseGallery>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  informationHome?: Maybe<Scalars['String']>;
  isAnswered?: Maybe<Scalars['Boolean']>;
  logoThumb?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  problem?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  question?: Maybe<Array<CaseQuestion>>;
  questionNotation?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  technology?: Maybe<Array<CaseTechnology>>;
  title?: Maybe<Scalars['String']>;
  title2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export class CaseCategoryType {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export class CaseGallery {
  case: Case;
  id: Scalars['ID'];
  path?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
};

export class CaseQuestion {
  answer?: Maybe<Array<CaseQuestionAnswer>>;
  case: Case;
  caseQuestionUser?: Maybe<Array<UserAnswer>>;
  id: Scalars['ID'];
  position: Scalars['Float'];
  text?: Maybe<Scalars['String']>;
};

export class CaseQuestionAnswer {
  caseQuestion: CaseQuestion;
  caseQuestionAnswerUser: Array<UserAnswer>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export class CaseTechnology {
  case: Case;
  id: Scalars['ID'];
  path?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  text?: Maybe<Scalars['String']>;
};

export class CreateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export class CreateUserInput {
  email: Scalars['String'];
  name: Scalars['String'];
  numberOfCollaborators: Scalars['String'];
  phone: Scalars['String'];
  role: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class EnumType {
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export class Mutation {
  /** Create new Enum Type */
  createEnumType: EnumType;
  /** Create new User */
  createUser: AuthType;
  login: AuthType;
  removeEnumType: DefaultMessage;
  /** Saved answer */
  saveAnswer: Array<Case>;
  updateEnumType: EnumType;
};


export class MutationCreateEnumTypeArgs {
  data: CreateEnumTypeInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveEnumTypeArgs {
  id: Scalars['String'];
};


export class MutationSaveAnswerArgs {
  data: SaveAnswerInput;
};


export class MutationUpdateEnumTypeArgs {
  data: UpdateEnumTypeInput;
};

export class Query {
  /** Return all answer By user Id */
  answerByUserId: Array<UserAnswerList>;
  /** Return Case By Id */
  caseById: Case;
  /** Return Type Category By Id */
  caseCaseCategoryTypeById: CaseCategoryType;
  /** Return Answer By Id */
  caseQuestionAnswerById: CaseQuestionAnswer;
  /** Return Question By Id */
  caseQuestionById: CaseQuestion;
  /** Return All Cases */
  cases: Array<Case>;
  /** Return All Type Categories */
  casesCategoriesType: Array<CaseCategoryType>;
  /** Return User Logged */
  currentUser: User;
  enumByType: Array<EnumType>;
  enumType: EnumType;
  enumTypes: Array<EnumType>;
  /** Return Role By Id */
  roleById: Role;
  /** Return All Roles */
  roles: Array<Role>;
  /** Return User By Email */
  userByEmail: User;
  /** Return User By Id */
  userById: User;
};


export class QueryAnswerByUserIdArgs {
  caseId: Scalars['String'];
  userId: Scalars['String'];
};


export class QueryCaseByIdArgs {
  id: Scalars['String'];
};


export class QueryCaseCaseCategoryTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryCaseQuestionAnswerByIdArgs {
  id: Scalars['String'];
};


export class QueryCaseQuestionByIdArgs {
  id: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeArgs {
  id: Scalars['String'];
};


export class QueryRoleByIdArgs {
  id: Scalars['String'];
};


export class QueryUserByEmailArgs {
  email: Scalars['String'];
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};

export class QuestionAnswerInput {
  questionAnswerId: Scalars['String'];
  questionId: Scalars['String'];
};

export class QuestionAnswerList {
  id: Scalars['String'];
  question: CaseQuestion;
  questionAnswer: CaseQuestionAnswer;
};

export class Role {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export class SaveAnswerInput {
  caseId: Scalars['String'];
  questions?: InputMaybe<Array<QuestionAnswerInput>>;
};

export class UpdateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export class User {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfCollaborators: Scalars['String'];
  phone: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  user: Array<UserAnswer>;
};

export class UserAnswer {
  case?: Maybe<Case>;
  id: Scalars['ID'];
  question?: Maybe<CaseQuestion>;
  questionAnswer?: Maybe<CaseQuestionAnswer>;
  user?: Maybe<User>;
};

export class UserAnswerList {
  case: Case;
  questions?: Maybe<Array<QuestionAnswerList>>;
  user: User;
};

export type DefaultMessageFragment = { status: number, message: string };

export type CaseQuestionFragment = { id: string, position: number, text?: string | null, answer?: Array<{ id: string, label?: string | null }> | null };

export type CaseFragment = { bgIcon?: string | null, icon?: string | null, bgImage?: string | null, informationHome?: string | null, bgRGBA?: string | null, client?: string | null, description?: string | null, description2?: string | null, externalLink?: string | null, id: string, isAnswered?: boolean | null, logoThumb?: string | null, position: number, problem?: string | null, project?: string | null, solution?: string | null, title?: string | null, title2?: string | null, url?: string | null, video?: string | null, gallery?: Array<{ id: string, path?: string | null, position: number }> | null, question?: Array<{ id: string, position: number, text?: string | null, answer?: Array<{ id: string, label?: string | null }> | null }> | null, technology?: Array<{ id: string, path?: string | null, position: number, text?: string | null }> | null };

export type UserFragment = { email: string, id: string, name: string, numberOfCollaborators: string, phone: string, photo?: string | null, role: string };

export type CasesQueryVariables = Exact<{ [key: string]: never; }>;


export type CasesQuery = { cases: Array<{ bgIcon?: string | null, icon?: string | null, bgImage?: string | null, informationHome?: string | null, bgRGBA?: string | null, client?: string | null, description?: string | null, description2?: string | null, externalLink?: string | null, id: string, isAnswered?: boolean | null, logoThumb?: string | null, position: number, problem?: string | null, project?: string | null, solution?: string | null, title?: string | null, title2?: string | null, url?: string | null, video?: string | null, gallery?: Array<{ id: string, path?: string | null, position: number }> | null, question?: Array<{ id: string, position: number, text?: string | null, answer?: Array<{ id: string, label?: string | null }> | null }> | null, technology?: Array<{ id: string, path?: string | null, position: number, text?: string | null }> | null }> };

export type SaveAnswerMutationVariables = Exact<{
  data: SaveAnswerInput;
}>;


export type SaveAnswerMutation = { saveAnswer: Array<{ bgIcon?: string | null, icon?: string | null, bgImage?: string | null, informationHome?: string | null, bgRGBA?: string | null, client?: string | null, description?: string | null, description2?: string | null, externalLink?: string | null, id: string, isAnswered?: boolean | null, logoThumb?: string | null, position: number, problem?: string | null, project?: string | null, solution?: string | null, title?: string | null, title2?: string | null, url?: string | null, video?: string | null, gallery?: Array<{ id: string, path?: string | null, position: number }> | null, question?: Array<{ id: string, position: number, text?: string | null, answer?: Array<{ id: string, label?: string | null }> | null }> | null, technology?: Array<{ id: string, path?: string | null, position: number, text?: string | null }> | null }> };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { roles: Array<{ name?: string | null, id: string }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { email: string, id: string, name: string, numberOfCollaborators: string, phone: string, photo?: string | null, role: string } };

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { token: string, user: { email: string, id: string, name: string, numberOfCollaborators: string, phone: string, photo?: string | null, role: string } } };

export type LoginMutationVariables = Exact<{
  data: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { email: string, id: string, name: string, numberOfCollaborators: string, phone: string, photo?: string | null, role: string } } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  status
  message
}
    `;
export const CaseQuestionFragmentDoc = gql`
    fragment CaseQuestion on CaseQuestion {
  answer {
    id
    label
  }
  id
  position
  text
}
    `;
export const CaseFragmentDoc = gql`
    fragment Case on Case {
  bgIcon
  icon
  bgImage
  informationHome
  bgRGBA
  client
  description
  description2
  externalLink
  gallery {
    id
    path
    position
  }
  id
  isAnswered
  logoThumb
  position
  problem
  project
  question {
    ...CaseQuestion
  }
  solution
  technology {
    id
    path
    position
    text
  }
  title
  title2
  url
  video
}
    ${CaseQuestionFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  email
  id
  name
  numberOfCollaborators
  phone
  photo
  role
}
    `;
export const CasesDocument = gql`
    query Cases {
  cases {
    ...Case
  }
}
    ${CaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CasesGQL extends Apollo.Query<CasesQuery, CasesQueryVariables> {
    document = CasesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAnswerDocument = gql`
    mutation SaveAnswer($data: SaveAnswerInput!) {
  saveAnswer(data: $data) {
    ...Case
  }
}
    ${CaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAnswerGQL extends Apollo.Mutation<SaveAnswerMutation, SaveAnswerMutationVariables> {
    document = SaveAnswerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RolesDocument = gql`
    query Roles {
  roles {
    name
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RolesGQL extends Apollo.Query<RolesQuery, RolesQueryVariables> {
    document = RolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($data: CreateUserInput!) {
  createUser(data: $data) {
    token
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($data: AuthInput!) {
  login(data: $data) {
    token
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }