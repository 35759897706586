import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';

// Extra Config
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routes: Routes = [
  {path: '', loadChildren: () => import('./core/landing/landing.module').then(m => m.LandingModule)},
  {
    path: 'case',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    loadChildren: () => import('./layout/main/main.module').then(m => m.MainModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
